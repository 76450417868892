const { compose, withState, withHandlers } = require('recompose')
const { connect } = require('react-redux')

const R = require('ramda')

const $message = require('coact/store/message')
const { get } = require('coact/utils/api/methods')
const { RequestError } = require('coact/utils/api/errors')


function delay (t, val) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(val)
    }, t)
  })
}

const apiUrl = 'api/route/ragicSyncer'

module.exports = compose(
  connect(),
  withState('progress', 'setProgress', null),
  withHandlers({
    watch: ({ setProgress }) => async () => {
      let isEnd = false
      while (!isEnd) {
        const { progress } = await get({ url: apiUrl, search: { watch: null } })
        setProgress(progress)

        if (R.prop('end', progress)) {
          isEnd = true
        } else {
          await delay(1000)
        }
      }
    },
    latest: ({ setProgress }) => async () => {
      const { progress } = await get({ url: apiUrl, search: { watch: null } })
      setProgress(progress)
    },
    close: ({ dispatch }) => async () => {
      await get({ url: apiUrl, search: { close: null } })
      dispatch($message.act.send({ message: 'process closed' }))
    },
    unlock: ({ dispatch }) => async () => {
      await get({ url: apiUrl, search: { unlock: null } })
      dispatch($message.act.send({ message: 'process unlocked' }))
    },
  }),
  withHandlers({
    launch: ({ watch, dispatch }) => async () => {
      try {
        await get({ url: apiUrl, search: { launch: null } })
        dispatch($message.act.send({ message: 'process launched' }))

        await watch()
      } catch (error) {
        if (error instanceof RequestError) {
          dispatch($message.act.send({ type: 'error', message: error.report }))
        } else {
          dispatch($message.act.send({ type: 'error', message: error.message }))
        }
      }
    },
  }),
)
