const R = require('ramda')

const coact = require('coact').default

const menu = require('./menu')

const syncConfig = require('./syncConfig')


const config = {
  title: 'Vigor App',
  menu,
}

const fullConfig = R.pipe(
  require('./customer/installClient'),
  require('./coach/installClient'),
  require('./sale/installClient'),
  require('./service/installClient'),
  require('./invoice/installClient'),
  require('./usage/installClient'),
  require('./tool/installClient'),
  require('./ragic/installClient')(syncConfig),
)(config)

coact(fullConfig)
