const mergeConfig = require('coact/config/mergeConfig')


const config = {
  models: {
    customers: {
      titile: 'customers',
      schema: require('./customers/schema'),
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
