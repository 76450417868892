module.exports = {
  ui: {
    main: {
      name: 'formEditor',
      fields: [
        ['name', 'custOfPaying.id', 'kpiType', 'custNumber'],
        ['mainCoach.id', 'custClass', 'athleteLevel'],
        ['apcCoach.id', 'custSort', 'doingSports'],
        ['aptCoach.id', 'cooperativePartner'],
      ],
    },
    contact: {
      name: 'formEditor',
      title: '聯絡資料',
      fields: [
        ['birthday', 'mobilePhone', 'homePhone', 'workPhone'],
        [{ path: 'email', span: 2 }, { path: 'address', span: 2 }],
        [{ path: 'note', multiline: true, span: 4 }],
      ],
    },
    development: {
      name: 'formEditor',
      title: '服務歷程',
      fields: [
        ['knowingReason', 'need'],
        [{ path: 'knowingDetail', span: 2 }],
        ['custOfReferral.id', 'willingToRecommend'],
        ['firstMeetDate', 'aptFEDate', 'apcFEDate', 'lastServiceDate'],
        ['closingDate', 'closingCoach.id', 'estimateNextPurchaseDate', 'estimateNextPurchaseAmount'],
      ],
    },
  },
  disabled: {
  },
}
