const mergeConfig = require('coact/config/mergeConfig')

const Page = require('./PageComponent')


const config = {
  routes: {
    earnedRevenues: {
      title: '發票收入認列資料',
      path: '/route/earnedRevenues',
      component: Page,
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
