module.exports = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    key: { type: 'String', label: '服務編號' },
    date: { type: 'Date', label: '服務日期' },
    customer: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '服務客戶_id', reference: 'customers', refKeys: ['name'], view: 'form' },
        name: { type: 'String', label: '服務客戶' },
      },
    },
    custOfPaying: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '購買客戶_id', reference: 'customers', refKeys: ['name'], view: 'form' },
        name: { type: 'String', label: '購買客戶' },
      },
    },
    course: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '課程內容_id', reference: 'courses', refKeys: ['name'] },
        name: { type: 'String', label: '課程內容' },
      },
    },
    coach: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '服務教練_id', reference: 'coachs', refKeys: ['key'], view: 'form', search: 'startWith' },
        key: { type: 'String', label: '服務教練' },
      },
    },
    location: { type: 'String', label: '服務地點', options: ['室內', '戶外'] },
    hours: { type: 'Number', label: '使用時數' },
    revenue: { type: 'Number', label: '消耗金額' },
    note: { type: 'String', label: '服務備註' },
  },
}
