const mergeConfig = require('coact/config/mergeConfig')

const UploadTemplate = require('./UploadTemplate').default


const config = {
  routes: {
    uploadTemplate: {
      title: '上傳 Template',
      path: '/route/uploadTemplate',
      component: UploadTemplate,
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
