const mergeDeep = require('coact/utils/mergeDeep')

const saleSchema = require('~/src/sale/data/sales/schema')
const serviceSchema = require('~/src/service/data/services/schema')

const usageSchema = {
  contents: {
    usage: {
      type: 'Object',
      label: '使用狀況',
      contents: {
        used: { type: 'Number', label: '已使用' },
        unused: { type: 'Number', label: '剩餘' },
        usages: {
          type: 'Array',
          contents: [{
            type: 'Object',
            contents: {
              service: serviceSchema,
              points: { type: 'Number', label: '使用點數' },
            },
          }],
        },
      },
    },
  },
}

module.exports = mergeDeep(saleSchema, usageSchema)
