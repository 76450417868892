const mergeConfig = require('coact/config/mergeConfig')


const config = {
  models: {
    sales: {
      title: 'sales',
      schema: require('./sales/schema'),
      // ...require('./prices/ui'),
    },
    salesOfHour: {
      title: 'salesOfHour',
      schema: require('./salesOfHour/schema'),
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
