module.exports = {
  ui: {
    main: {
      name: 'formEditor',
      fields: [
        ['no', 'date', 'amount'],
        ['paymentMethod', 'paymentStatus', 'paymentDate'],
        ['note'],
      ],
    },
    lines: {
      name: 'tableEditor',
      path: 'lines',
      fields: [
        // { path: 'sale.id', refKeys: ['key', 'customer.id.name'] },
        { path: 'sale.id' },
        { path: 'sale.id.customer.id.name', label: '客戶' },
        { path: 'amount' },
      ],
      copyable: false,
      deletable: true,
    },
  },
  disabled: {
    // 'lines.0.sale.id.customer.id.name': true,
  },
  populationdepth: 2,
}
