const mergeSystemFields = require('coact/utils/schema/mergeSystemFields')
const mergeClientRequiredChanges = require('coact/utils/schema/mergeClientRequiredChanges')

function create (schema) {
  return mergeSystemFields(schema)
}

function adaptClient (schema) {
  return mergeClientRequiredChanges(schema)
}

module.exports = {
  create,
  adaptClient,
}
