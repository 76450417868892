const schema = require('./schema')
const listPage = require('./listPage')
const editPage = require('./editPage')

const config = {
  title: '客戶資料',
  schema,
  list: listPage,
  edit: editPage,
}

module.exports = config
