const schema = require('./schema')
const listPage = require('./listPage')
const editPage = require('./editPage')

const config = {
  title: '發票資料',
  schema,
  list: listPage,
  edit: editPage,
}

module.exports = config
