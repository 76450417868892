const autofill = {
  // lines: {
  //   src: 'sale.id',
  //   fill: src => ({
  //     sale: {
  //       name: src.name,
  //     },
  //   }),
  // },
}

module.exports = {
  autofill,
}
