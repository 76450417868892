// const invoices = require('./invoice/data/invoices')

// run: {
//   cache: { plan: 'cacheAll' }, // cacheAll, refreshAll
//   adapt: { plan: 'newly' }, // newly, all
//   link: { plan: 'newly' }, // newly, all
//   save: { plan: 'newly' }, // newly, all
// },
module.exports = {
  syncConfigPath: 'src/syncConfig.js',
  appConfigPath: 'src/serverConfig.js',
  syncTasks: [
    // /*
    {
      path: 'customer/1',
      saveTo: 'customers', // customers.ragic
      updatedAtEid: '1000614',

      // run: ['save'],
      // savePlan: 'all',
      // plan: 'updateAll',
      // run: ['cache'],

      // plan: 'cacheAll', // 'cacheAll', 'refreshAll', 'updateDirty'
      // adaptPlan: 'all',
      // linkPlan: 'all',
      // TODO fieldOfUpdatedAt: 1000037
      // adaptPlan: 'all',
      // linkPlan: 'all',
      // batchSize: 1000,
    },
    // */
    /*
    {
      path: 'sales/2',
      saveTo: 'salesOfHour',
      // run: ['save'],
      // subtables: true,
      // plan: 'refreshAll',
      // adaptPlan: 'all',
      // linkPlan: 'all',
      // savePlan: 'all',
    },
    */
    /*
    {
      path: 'sales/1',
      saveTo: 'servicesOfHour',
      // run: ['save'],
      // subtables: true,
      // plan: 'refreshAll',
      // adaptPlan: 'all',
      // linkPlan: 'all',
      // savePlan: 'all',
    },
    */
    /*
    {
      path: 'sales/4',
      saveTo: 'invoices',
      subtables: true,
      adaptPlan: 'all',
      linkPlan: 'all',
      batchSize: 100,
    },
    */
  ],
  models: {
    customers: {
      schema: require('./customer/data/customers/schema'),
    },
    servicesOfHour: {
      schema: require('./service/data/servicesOfHour/schema'),
    },
    salesOfHour: {
      schema: require('./sale/data/salesOfHour/schema'),
    },
    // invoices,
  },
}
