module.exports = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    name: { type: 'String', label: '姓名' },
    custOfPaying: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '課程使用客戶_id', reference: 'customers', refKeys: ['name'], view: 'form' },
        name: { type: 'String', label: '課程使用客戶' },
      },
    },
    kpiType: { type: 'String', label: 'KPI分類', options: ['A', 'B', 'C'] },
    custNumber: { type: 'String', label: '客戶序號' },
    mainCoach: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '負責教練_id', reference: 'coachs', refKeys: ['key'], view: 'form', search: 'startWith' },
        key: { type: 'String', label: '負責教練' },
      },
    },
    apcCoach: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: 'APC教練_id', reference: 'coachs', refKeys: ['key'], view: 'form', search: 'startWith' },
        key: { type: 'String', label: 'APC教練' },
      },
    },
    aptCoach: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: 'APT教練_id', reference: 'coachs', refKeys: ['key'], view: 'form', search: 'startWith' },
        key: { type: 'String', label: 'APT教練' },
      },
    },
    custClass: { // computed field
      type: 'String', label: '重要性', options: ['A', 'B', 'C', 'D', 'E', 'FE'],
    },
    custSort: {
      type: 'String',
      label: '客戶類別',
      options: ['有效客戶', '歷史客戶', '潛在客戶', '特殊客戶', '專案客戶', '無效客戶'],
    },
    cooperativePartner: { type: 'String', label: '所屬合作伙伴' },
    doingSports: { type: 'String', label: '運動項目' },
    athleteLevel: { type: 'String', label: '是否為運動員', options: ['運動員', '一般人'] },

    // contact
    birthday: { type: 'Date', label: '生日' },
    mobilePhone: { type: 'String', label: '手機' },
    homePhone: { type: 'String', label: '室內電話' },
    workPhone: { type: 'String', label: '公司電話' },
    email: { type: 'String', label: 'E-mail' },
    address: { type: 'String', label: '聯絡地址' },

    // development
    knowingReason: {
      type: 'String',
      label: '來源',
      options: ['來店客', '網路', '行銷專案', '廣告雜誌', '介紹', '活動', '教練開發', '台新無限卡'],
    },
    knowingDetail: { type: 'String', label: '詳細來源/介紹人' },
    need: { type: 'String', label: '來店目的' },
    custOfReferral: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '推薦人_id', reference: 'customers', refKeys: ['name'], view: 'form' },
        name: { type: 'String', label: '推薦人' },
      },
    },
    willingToRecommend: { type: 'String', label: '推薦給朋友', options: ['是'] },
    firstMeetDate: { type: 'Date', label: '首次來店日期' },
    aptFEDate: { type: 'Date', label: '體驗APT日期' },
    apcFEDate: { type: 'Date', label: '體驗APC日期' },
    closingDate: { type: 'Date', label: '成交日' },
    closingCoach: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '成交教練_id', reference: 'coachs', refKeys: ['key'], view: 'form', search: 'startWith' },
        key: { type: 'String', label: '成交教練' },
      },
    },
    lastServiceDate: { type: 'Date', label: '最近來店日期' },
    estimateNextPurchaseDate: { type: 'Date', label: '預估下次購買日' },
    estimateNextPurchaseAmount: { type: 'Number', label: '預估購買金額' },
    note: { type: 'String', label: '備註' },

    deprecatedField: {
      type: 'Object',
      contents: {
        allPoints: { type: 'String', label: '購買點數' },
        unusedPoints: { type: 'String', label: '剩餘點數' },
        alertMessage: { type: 'String', label: '課程剩餘提示' },
      },
    },
  },
}
