const mergeDeep = require('coact/utils/mergeDeep')

const saleSchema = require('~/src/sale/data/sales/schema')
const serviceSchema = require('~/src/service/data/services/schema')

const usageSchema = {
  contents: {
    usedSales: {
      type: 'Array',
      contents: [saleSchema],
    },
  },
}

module.exports = mergeDeep(serviceSchema, usageSchema)
