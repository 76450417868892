module.exports = {
  ui: {
    main: {
      name: 'formEditor',
      fields: [
        ['key', 'date'],
        ['customer.id', 'coach.id'],
        ['type', 'points', 'amount'],
        ['usedPoints', 'unusedPoints'],
        ['note'],
      ],
    },
    usageStatus: {
      name: 'formEditor',
      title: '使用狀況',
      fields: [
        ['usage.used', 'usage.unused'],
      ],
    },
    usages: {
      name: 'tableEditor',
      path: 'usage.usages',
      fields: [
        { path: 'service.date' },
        { path: 'service.customer.id.name', label: '使用人' },
        { path: 'service.course.id.name', label: '課程' },
        { path: 'service.coach.id.key', label: '教練' },
        { path: 'service.hours' },
        { path: 'points', label: '使用此購買點數' },
      ],
      copyable: false,
      deletable: false,
      appendEmpty: false,
    },
  },
  disabled: {
    key: true,
  },
}
