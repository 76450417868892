const R = require('ramda')
const mergeConfig = require('coact/config/mergeConfig')

const { adaptClient } = require('../../utils/schema')

const coachs = require('./coachs')


const adaptClientModel = R.over(R.lensProp('schema'), adaptClient)

const config = {
  models: {
    coachs: adaptClientModel(coachs),
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
