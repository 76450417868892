module.exports = [
  {
    category: '基本資料管理',
    features: ['customers:form', 'coachs:form'],
  },
  {
    category: '客戶購買',
    features: ['sales:form', 'salesOfHour', 'invoices:form', 'invoiceLines'],
  },
  {
    category: '服務紀錄',
    features: ['services:form', 'courses', 'servicesOfHour'],
  },
  {
    category: '發票管理',
    features: ['earnedRevenues'],
  },
  {
    category: '資料同步',
    features: ['ragicSyncer'],
  },
]
