module.exports = {
  ui: {
    main: {
      name: 'formEditor',
      fields: [
        ['key'],
        ['date'],
        ['customer.id', 'custOfPaying.id'],
        ['course.id'],
        ['coach.id'],
        ['location'],
        ['hours', 'points', 'useAmount'],
        [{ path: 'note', multiline: true, span: 3 }],
      ],
    },
    sales: {
      name: 'tableEditor',
      path: 'usedSales',
      fields: [
        { path: 'key' },
        { path: 'date' },
        { path: 'points' },
        { path: 'type' },
        { path: 'usedPoints' },
        { path: 'unusedPoints' },
      ],
      copyable: false,
      deletable: false,
      appendEmpty: false,
    },
  },
  required: {
    date: true,
    'customer.id': true,
    'custOfPaying.id': true,
    note: true,
  },
  disabled: {
    key: true,
  },
  actions: {
    saveAndCopy: {
      authChecker: () => false,
    },
  },
}
