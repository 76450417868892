const R = require('ramda')
const buildQueryString = require('coact/utils/api/buildQueryString')
const { builtinEffects: editEffects } = require('coact/saga/editSaga')

module.exports = {
  actions: {
    saveAndCopy: {
      authChecker: () => false,
    },
    downloadXlsx: {
      text: '下載使用紀錄',
      effect: { name: 'downloadXlsx' },
      props: { variant: 'contained', color: 'primary' },
      preventDefault: true,
    },
  },
  effect: {
    downloadXlsx: function* downloadXlsx (payload, ctx) {
      const savedData = yield ctx.S.call(editEffects.save)
      if (savedData) {
        const key = R.path(['key'], savedData)
        const name = R.path(['customer', 'id', 'name'], savedData)

        const query = {
          id: savedData._id,
          fullname: `${key}-${name}.xlsx`,
        }

        window.open(
          `api/print/saleUsageStatus/xlsx?${buildQueryString(query)}`,
          '_blank',
        )
      }
    },
  },
}
