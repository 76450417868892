module.exports = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    key: { type: 'String', label: '服務編號' },
    date: {
      type: 'Date',
      label: '服務日期',
      format: 'YYYY/MM/DD HH:mm',
      parseFormat: [
        'YYYYMMDD',
        'YYYY-MM-DD',
        'YYYY/MM/DD',
        'YYYYMMDDHHmmss',
        'YYYYMMDDHHmm',
        'YYYY-MM-DD HH:mm:ss',
        'YYYY/MM/DD HH:mm:ss',
        'YYYY/MM/DD HH:mm',
      ],
    },
    customer: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '服務客戶', reference: 'customers', refKeys: ['name'], view: 'form' },
        name: { type: 'String', label: '服務客戶名' },
      },
    },
    custOfPaying: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '購買客戶', reference: 'customers', refKeys: ['name'], view: 'form' },
        name: { type: 'String', label: '購買客戶名' },
      },
    },
    course: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '課程', reference: 'courses', refKeys: ['name'] },
        name: { type: 'String', label: '課程內容' },
      },
    },
    coach: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '服務教練', reference: 'coachs', refKeys: ['key'], view: 'form', search: 'startWith' },
        key: { type: 'String', label: '教練名' },
      },
    },
    location: { type: 'String', label: '服務地點', options: ['室內', '戶外'] },
    hours: { type: 'Number', label: '時數' },
    points: { type: 'Number', label: '扣抵點數' },
    useAmount: { type: 'Number', label: '消耗金額' },
    note: { type: 'String', label: '備註' },
    ragicKey: { type: 'String', label: 'Ragic編號' },
  },
}
