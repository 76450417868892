const dataSchema = require('../entity/dataAccess/schema')


const schema = dataSchema.coach

const index = {
}


module.exports = {
  schema,
  index,
}
