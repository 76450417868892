const mergeConfig = require('coact/config/mergeConfig')


const config = {
  models: {
    courses: {
      // title: 'courses',
      title: '課程類別',
      schema: require('./courses/schema'),
      // ...require('./prices/ui'),
    },
    services: {
      title: 'services',
      schema: require('./services/schema'),
      // ...require('./prices/ui'),
    },
    servicesOfHour: {
      title: 'servicesOfHour',
      schema: require('./servicesOfHour/schema'),
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
