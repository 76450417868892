module.exports = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    key: { type: 'String', label: '購買編號' },
    date: { type: 'Date', label: '購買日期' },
    customer: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '購買客戶_id', reference: 'customers', refKeys: ['name'], view: 'form' },
        name: { type: 'String', label: '購買客戶' },
      },
    },
    coach: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '成交教練_id', reference: 'coachs', refKeys: ['key'], view: 'form', search: 'startWith' },
        key: { type: 'String', label: '成交教練' },
      },
    },
    type: { type: 'String', label: '購買類別', options: ['首購', '續購', '體驗', '專案'] },
    hours: { type: 'Number', label: '購買時數' },
    amount: { type: 'Number', label: '購買金額' },
    // amountPaid: { type: 'Number', label: '已付金額' },
    // receivable: { type: 'Number', label: '應收帳款' },
    // receivable: { type: 'Number', label: '應收帳款' },
    // unusedHours: { type: 'Number', label: '剩餘時數' },
    // amountPerHour: { type: 'Number', label: '單時金額' },
    // amountUnused: { type: 'Number', label: '剩餘金額' },
    note: { type: 'String', label: '購買備註' },
  },
}
