const mergeConfig = require('coact/config/mergeConfig')


const config = {
  models: {
    invoices: {
      title: 'invoices',
      schema: require('./invoices/schema'),
      // ...require('./prices/ui'),
    },
    invoiceLines: {
      title: 'invoiceLines',
      schema: require('./invoiceLines/schema'),
    },
  },
}


module.exports = fullConfig => mergeConfig(fullConfig, config)
