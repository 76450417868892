const R = require('ramda')

const mergeDeep = require('coact/utils/mergeDeep')
const toSchemaPath = require('coact/utils/schema/toSchemaPath')

const invoiceSchema = require('~/src/invoice/data/invoices/schema')
const invoiceLineSchema = require('~/src/invoice/data/invoiceLines/schema')

const linesSchema = R.pipe(
  R.assocPath(
    toSchemaPath('lines'),
    { type: 'Array', contents: [invoiceLineSchema] },
  ),
  // R.assocPath(
  //   [...toSchemaPath('lines.0.sale.id'), 'refKeys'],
  //   ['key', ['customer', 'id', 'name']],
  // ),
)({ })


module.exports = mergeDeep(invoiceSchema, linesSchema)
