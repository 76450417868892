const autofill = {
  '': {
    src: 'customer.id',
    fill: src => ({
      custOfPaying: {
        id: src.custOfPaying.id || src,
      },
    }),
  },
}

module.exports = {
  autofill,
}
