const coach = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    key: { type: 'String', label: '代碼' },
    name: { type: 'String', label: '姓名' },
  },
}

module.exports = {
  coach,
}
