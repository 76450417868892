module.exports = {
  type: 'Object',
  contents: {
    sale: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '購買', reference: 'sales', refKeys: ['key'], view: 'form' },
        key: { type: 'String', label: '購買編號' },
      },
    },
    invoice: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '發票', reference: 'invoices', refKeys: ['no'], view: 'form' },
        seq: { type: 'Number', label: '項次' },
        no: { type: 'String', label: '發票號碼' },
      },
    },
    amount: { type: 'Number', label: '金額' },
  },
}
