module.exports = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    no: { type: 'String', label: '發票號碼' },
    date: { type: 'Date', label: '發票日期' },
    amount: { type: 'Number', label: '發票金額' },
    paymentMethod: { type: 'String', label: '付款方式', options: ['信用卡', '轉帳', '現金', '支票'] },
    paymentStatus: { type: 'String', label: '收款狀態', options: ['未收款', '請款中', '已收'] },
    // payer: { type: 'String', label: '購買人' },
    paymentDate: { type: 'Date', label: '付款日期' },
    note: { type: 'String', label: '備註' },
    // lines: {
    //   type: 'Array',
    //   ragicField: '_subtable_1000020',
    //   contents: [{
    //     type: 'Object',
    //     contents: {
    //       sale: {
    //         type: 'Object',
    //         contents: {
    //           id: {
    //             type: 'ObjectId',
    //             label: '購買編號',
    //             reference: 'sales',
    //             refKeys: ['key'],
    //             view: 'form',
    //           },
    //         },
    //       },
    //     },
    //   }],
    // },

    // status: { type: 'String', label: '狀態' },
    // amountUsed: { type: 'Number', label: '消耗金額' },
    // amountUnused: { type: 'Number', label: '剩餘金額' },
    // startDate: { type: 'Date', label: '開始消耗' },
    // endDate: { type: 'Date', label: '結束消耗' },
  },
}
