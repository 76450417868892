module.exports = {
  type: 'Object',
  contents: {
    _id: { type: 'ObjectId' },
    key: { type: 'String', label: '購買編號' },
    customer: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '購買客戶', reference: 'customers', refKeys: ['name'], view: 'form' },
        name: { type: 'String', label: '客戶名' },
      },
    },
    date: {
      type: 'Date',
      label: '購買日期',
      parseFormat: ['MMDD', 'YYYYMMDD', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    },
    type: { type: 'String', label: '購買類別', options: ['首購', '續購', '體驗', '專案', '轉讓'] },
    points: { type: 'Number', label: '購買點數' },
    amount: { type: 'Number', label: '購買金額' },
    coach: {
      type: 'Object',
      contents: {
        id: { type: 'ObjectId', label: '成交教練', reference: 'coachs', refKeys: ['key'], view: 'form', search: 'startWith' },
        key: { type: 'String', label: '教練' },
      },
    },
    membershipLevel: { type: 'String', label: '會員等級', options: ['金質', '銀質', '白金', '金鑽', '終身'] },
    note: { type: 'String', label: '購買備註' },

    usedPoints: { type: 'Number', label: '已用點數' },
    unusedPoints: { type: 'Number', label: '剩餘點數' },

    deprecatedField: {
      type: 'Object',
      contents: {
        invoice: { type: 'String', label: '發票號碼' },
        unusedPoints: { type: 'String', label: '剩餘點數' },
        unusedAmount: { type: 'String', label: '剩餘金額' },
        amountPerPoint: { type: 'String', label: '單點金額' },
        paymentMethod: { type: 'String', label: '收款狀態' },
        paymentStatus: { type: 'String', label: '付款方式' },
      },
    },
  },
}
