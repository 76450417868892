module.exports = {
  ui: {
    fields: [
      'name', 'custOfPaying.id', 'custOfReferral.id', 'kpiType', 'custClass',
      'custSort', 'mainCoach.id', 'mobilePhone', 'homePhone', 'email',
    ],
  },
  populationdepth: 1,
  populations: 'customers,coachs',
}
