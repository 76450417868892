module.exports = {
  ui: {
    fields: [
      'key', 'customer.id', 'custOfPaying.id', 'date',
      'course.id', 'coach.id', 'hours', 'points', 'useAmount', 'location',
    ],
  },
  populationdepth: 1,
  populations: 'customers,coachs,courses',
}
