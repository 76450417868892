const R = require('ramda')
const mergeConfig = require('coact/config/mergeConfig')

const clientConfig = require('./clientConfig')


const config = R.assocPath(
  ['models', 'invoices', 'views', 'form'],
  clientConfig,
  { },
)


module.exports = fullConfig => mergeConfig(fullConfig, config)
